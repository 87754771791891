<template>
<div>
    <div class="barra"></div>

  <div class="container">
         <div v-if="loading">
             <loading/>                 
         </div>

         <div v-if="!loading">
            <div class="row text-center">
                <div class="col">
                    <!-- Nav -->
                    <div class="row justify-content-center">
                        <div class="dtema col col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <div class="row justify-content-between">
                                <router-link :to="{ name: 'Favorites' }" class="col- align-self-center dtema_icon">
                                    <i class="fas fa-chevron-left"></i>
                                </router-link>

                                <div class="col text-center align-self-center nav_title_color">
                                    <h5 class="tbar">{{favoriteId.getFavorite.question.course.name}} <br> 
                                    {{favoriteId.getFavorite.question.team.name}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Favorites -->
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6" style="font-size: 16px; text-align: right; margin-right: 10px; cursor: pointer;">
                            <div v-if="myFavorite" v-on:click="daleteFavorite" :style="heartColor.favorite">
                                <i class="fas fa-heart favorite"></i>
                                {{ getFavorite.length }}
                            </div>
                            <div v-else v-on:click="addFavorite" :style="heartColor.unfavorite">
                                <i class="fas fa-heart favorite"></i>
                                {{ getFavorite.length }}
                            </div>
                        </div>
                    </div>
                    <!-- Question -->
                    <div class="row py-4 justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 text-left">
                            <div v-html="favoriteId.getFavorite.question.body"></div>
                        </div>
                    </div>
                    <!-- Year -->
                    <div class="row py-4 justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 text-right">
                            <p class="text-muted">
                                {{ favoriteId.getFavorite.question.block.name }} - 
                                {{ favoriteId.getFavorite.question.year.name }}</p>
                        </div>
                    </div>
                    <!-- Answer -->
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <p class="btn_long btn_text_color" v-if="favoriteId.getFavorite.question.answer.a != 'sinClave'" >
                                {{favoriteId.getFavorite.question.answer.a}}
                            </p>                        
                            <p class="btn_long btn_text_color" v-if="favoriteId.getFavorite.question.answer.b != 'sinClave'" >
                                {{favoriteId.getFavorite.question.answer.b}}
                            </p>                        
                            <p class="btn_long btn_text_color" v-if="favoriteId.getFavorite.question.answer.c != 'sinClave'" >
                                {{favoriteId.getFavorite.question.answer.c}}
                            </p>                        
                            <p class="btn_long btn_text_color" v-if="favoriteId.getFavorite.question.answer.d != 'sinClave'" >
                                {{favoriteId.getFavorite.question.answer.d}}
                            </p>                        
                            <p class="btn_long btn_text_color" v-if="favoriteId.getFavorite.question.answer.e != 'sinClave'" >
                                {{favoriteId.getFavorite.question.answer.e}}
                            </p>                        
                        </div>
                    </div>
                    <!-- Key -->
                    <div class="row py-2 justify-content-center">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                            <p class="btn_long_key btn_text_color" v-if="favoriteId.getFavorite.question.answer.c != 'sinClave'" >
                                {{favoriteId.getFavorite.question.key.keys}}
                            </p> 
                        </div>
                    </div>
   
                    <!-- Actions -->
                    <div class="row py-2 justify-content-center">
                        <div class="col">
                            <div class="row justify-content-center">
                                <div class="col- button_actions button_click" v-on:click="showComments">
                                    <div class="button_actions_icon"><i class="fas fa-envelope"></i></div>
                                    <div> Comentar </div>
                                    <div class="button_actions_icon" v-if="favoriteId.getFavorite.question.comments_count > 0">
                                        <b>·</b> {{ favoriteId.getFavorite.question.comments_count }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <!-- start comentario Publicado loading --> 
            <div v-if="loadingComment">
                <loadingSection/>
            </div>
            <!-- Fin de Comentario Publicado -->


            <!-- end comentario Publicado loading --> 
            <div class="row" v-if="!loadingComment" v-show="showFieldComment" >
                <div class="col">
                    <div class="row justify-content-center">
                        <div class="col-" hidden>
                            <a href="">
                                <div class="dCirculo">
                                    <img class="imgAvatar" src="/uploads/avatars/2019-06-19-16-58-14.jpg">
                                </div>
                            </a>
                        </div>
                        <!-- Textares -->
                        <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 text-center">
                            <form>
                                <div class="form-group">
                                    <textarea maxlength="200" class="form-control" v-model="message" placeholder="Agrega un comentario..." rows="2"></textarea>
                                </div>                                            
                                <button type="button" v-on:click='onSendComment' class="btn btn-primary">Enviar</button>
                            </form>
                        </div>
                    </div>                    
                
                <!-- start comentario Publicado loading -->   
                <div class="row" v-if="loadingCommentSend">                
                    <loadingSectionSendCommenst/>
                </div>
                <!-- start comentario Publicado loading -->   
                                    
                <!-- start comentario Publicado -->                   
                <div class="row ddBody" v-for="comment in comments" :key="comment.id">
                    <div class="col-" hidden>
                        <a href="">
                            <div class="dCirculo">
                                <img class="imgAvatar" src="/uploads/avatars/2019-06-19-16-58-14.jpg">
                            </div>
                        </a>
                    </div>
                    <div class="col tDatos align-self-center">
                        <span>{{comment.username}}</span>
                        <p>{{comment.comment}}</p>
                        <div hidden>
                            <a href="#">Responder</a>
                            <i class="fas fa-thumbs-up btn-like"></i>                                            
                        </div>
                    </div>


                    <div class="col-auto" hidden>
                        <!--  button -->
                        <div class="btn-group">
                            <button type="button" class="btn btnpuntos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <button class="dropdown-item QuestionCommentInap" type="button" data-id="comment.id">Reportar como inapropiado</button>
                                <button class="dropdown-item QuestionCommentSpam" type="button" data-id="comment.id">Reportar como spam</button>
                                <hr>
                                <button class="dropdown-item spamComment" type="button" data-id="comment.id">Eliminar comentario</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

        </div>

     </div>
</div>

</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import loading from "@/views/student/constants/loading/round.vue";
import loadingSection from "@/views/student/constants/loading-section/comments.vue";
import loadingSectionSendCommenst from "@/views/student/constants/loading-section/send-comment.vue";


export default {
    name: 'cFavorites',
    components: {
        loading,
        loadingSection,
        loadingSectionSendCommenst
    },
    data(){
            return{
                canNextQuestion: true,
                question: [],
                question_id: this.$route.params.url,
                message: "",
                showFieldComment: false,
                canGetComments: true,
                comments: [],                
                getFavorite: [],            
                myFavorite:[],
                loading: true,
                loadingComment: false,
                loadingCommentSend: false,
                heartColor: {
                favorite: {
                    color: '',
                },
                unfavorite: {
                    color: '',
                },
            },
            }
        },
        mounted() {
            },
        async created() {
            await this.$store.dispatch('setFavoriteId');
            this.loading = false;
            await this.getFavorites();
            await this.mFavorite();
        },
        methods:{
            viewFavorite(){
                const url = this.appDomainApi1 + "viewFavorite/" + this.question_id
                axios.get(url,)
                    .then((resp) => {
                        if(resp.data.code == 200 ){
                            this.question = resp.data.getFavorite.question
                            this.loading = false;                          
                        } else {
                            this.$router.push({ name: 'Error403' })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })

            },    
            showComments(){
                if(this.canGetComments){
                    this.loadingComment = true
                    this.canGetComments = false
                    this.getComments()
                }
                this.showFieldComment = !this.showFieldComment
            },    
            getComments(){
                const url = this.appDomainApi1 + 'questions/' + this.question_id + '/comments';
                axios.get(url)
                    .then((resp) => {
                        if(resp.data.code == 200){
                            this.comments = resp.data.comments
                            this.loadingComment = false
                            this.loadingCommentSend = false
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            onSendComment(){
                this.loadingCommentSend = true
                this.sendComment()
            },
            sendComment() {
                if (this.message != "") {
                    const url = this.appDomainApi1 + 'questions/' + this.question_id + '/comments';
                    axios.post(url, {
                            comment: this.message
                        })
                        .then((resp) => {
                            console.log(resp)
                            this.message = "";
                            this.getComments();
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            },
            addFavorite() {
            const url = this.appDomainApi1 + 'favorite/' + this.favoriteId.getFavorite.question_id + "/addFavorite";
            axios
                .get(url, )
                .then((resp) => {
                    this.heartColor.favorite.color = '#006df0';
                    this.getFavorites();
                    this.mFavorite();
                    this.showFavorite = false;
                    console.log(resp)
                })
                .catch((error) => {
                    console.log(error);
                });
            },
            getFavorites() {
                const url = this.appDomainApi1 + "favorite/" + this.favoriteId.getFavorite.question_id + "/getFavorites";
                axios
                    .get(url, )
                    .then((resp) => {
                        if (resp.data.code === 200) {
                            this.getFavorite = resp.data.getFavorite;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            mFavorite() {
                const url = this.appDomainApi1 + "favorite/" + this.favoriteId.getFavorite.question_id + "/myFavorite";
                axios
                    .get(url, )
                    .then((resp) => {
                        if (resp.data.code === 200) {
                            this.myFavorite = resp.data.myFavorite;
                            this.heartColor.favorite.color = '#006df0';
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            daleteFavorite() {
                const url = this.appDomainApi1 + "favorite/" + this.favoriteId.getFavorite.question_id + "/deleteFavorite";
                axios
                    .delete(url, {
                        question: this.quistion_id,
                    })
                    .then(() => {
                        this.getFavorites();
                        this.mFavorite();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        },
        computed: {
            ...mapState(['favoriteId',])
        }
}
</script>

<style>
.barra {
  height: 100px;
}
.button_actions {
    width: 10rem;
    height: 3rem;
    padding: .5rem;
    margin: .5rem;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9EA9A7;
}
.button_click:hover{
    box-shadow: 5px 5px 15px -3px #76767687;
    cursor: pointer;
}

.button_actions_icon {
    padding-left: .5rem;
    padding-right: .5rem;
}
.dtema_icon{
    padding-right: 1rem;
    padding-left: 1rem;
    color: white;
}
.dtema{
    background: #0c2790;
	}
.nav_title_color{
    color: #ffffff;
}
.btn_long{
    padding: .5rem;
    margin: .5rem;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn_long_key{
    padding: .5rem;
    margin: .5rem;
    background: #7cdc7c;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9EA9A7;
}
.btn_text_color{
    color: #2c3e50;
}
.btn_action_text_color{
    color: #2c3e50;
}
</style>
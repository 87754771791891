<template>
  <div class="container">
      <favoriteShow />
  </div>
</template>
<script>
import favoriteShow from '@/components/student/favorite/show.vue'

export default {
  name: 'dataStudent',
  components: {
    favoriteShow
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  }
}
</script>

<style scope>

</style>